import { useStoreState } from 'easy-peasy';
import { useEffect } from 'react';

const Notifications = () => { 
  const receivedFriendRequests = useStoreState((state) => state.friendRequests.receivedFriendRequests);

  useEffect(() => {
  }, [receivedFriendRequests]);

  return (
    <div>
      { JSON.stringify(receivedFriendRequests) }
    </div>
  )
}
 
export default Notifications;