import { useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';

import {
  BellIcon as BellOutlineIcon,
  Cog6ToothIcon as Cog6ToothOutlineIcon,
  EnvelopeIcon as EnvelopeOutlineIcon,
  GlobeAltIcon as GlobeAltOutlineIcon,
  HeartIcon as HeartOutlineIcon,
  HomeIcon as HomeOutlineIcon
} from '@heroicons/react/24/outline';

import {
  BellIcon as BellSolidIcon,
  Cog6ToothIcon as Cog6ToothSolidIcon,
  EnvelopeIcon as EnvelopeSolidIcon,
  GlobeAltIcon as GlobeAltSolidIcon,
  HeartIcon as HeartSolidIcon,
  HomeIcon as HomeSolidIcon
} from '@heroicons/react/24/solid';

import logo from '../../assets/images/logo.png';
import IconBadge from '../../components/ui/IconBadge/IconBadge';

const Sidenav = () => {
  const navigate = useNavigate();

  const account = useStoreState((state) => state.account.data);
  const myProfile = useStoreState((state) => state.profile.myProfile);
  const receivedFriendRequests = useStoreState((state) => state.friendRequests.receivedFriendRequests);

  const [currentTarget, setCurrentTarget] = useState('/');
  const [notificationCount, setNotificationCount] = useState(0);

  const [navigation, setNavigation] = useState([
    { name: 'Home', href: '/', icon: HomeOutlineIcon, selectedIcon: HomeSolidIcon, displayMobile: true },
    { name: 'Wellness', href: '/wellness', icon: HeartOutlineIcon, selectedIcon: HeartSolidIcon, displayMobile: true },
    { name: 'Notifications', href: '/notifications', icon: BellOutlineIcon, badge: notificationCount, selectedIcon: BellSolidIcon, displayMobile: true },
    { name: 'Messages', href: '/messages', icon: EnvelopeOutlineIcon, selectedIcon: EnvelopeSolidIcon, displayMobile: false },
    { name: 'Settings', href: '/settings', icon: Cog6ToothOutlineIcon, selectedIcon: Cog6ToothSolidIcon, displayMobile: true }
  ]);

  useEffect(() => {
    setNotificationCount(receivedFriendRequests?.length > 0 ? receivedFriendRequests.length : 0);
    setNavigation([
      { name: 'Home', href: '/', icon: HomeOutlineIcon, selectedIcon: HomeSolidIcon, displayMobile: true },
      { name: 'Wellness', href: '/wellness', icon: HeartOutlineIcon, selectedIcon: HeartSolidIcon, displayMobile: true },
      { name: 'Notifications', href: '/notifications', icon: BellOutlineIcon, badge: notificationCount, selectedIcon: BellSolidIcon, displayMobile: true },
      { name: 'Messages', href: '/messages', icon: EnvelopeOutlineIcon, selectedIcon: EnvelopeSolidIcon, displayMobile: false },
      { name: 'Settings', href: '/settings', icon: Cog6ToothOutlineIcon, selectedIcon: Cog6ToothSolidIcon, displayMobile: true }
    ]);
  }, [myProfile, receivedFriendRequests]);

  useEffect(() => {
    if (account.isAdmin) {
      let adminNav = navigation;
      adminNav.push({ name: 'Admin', href: '/admin', icon: GlobeAltOutlineIcon, selectedIcon: GlobeAltSolidIcon, displayMobile: false });
      setNavigation(adminNav)
    }
  }, [account]);
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  const navigateTo = (href) => {
    setCurrentTarget(href);
    navigate(href);
  };

  return (
    <div>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:z-50 md:flex xl:w-80 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col overflow-y-auto border-r border-gray-200 bg-white dark:bg-slate-900 dark:border-slate-800 px-6">
          <div className="flex h-16 shrink-0 items-center">
            <img
              className="h-8 w-auto md:m-auto xl:m-0"
              src={logo}
              alt="HelloLife"
            />
            <div className="ml-2 text-xl text-slate-800 dark:text-white md:hidden xl:block">HelloLife</div>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 xl:space-y-1 md:space-y-3">
                  {navigation.map((item, index) => (
                    <li key={item.name} className="hover:cursor-pointer">
                      <button
                        onClick={() => navigateTo(item.href)}
                        className={classNames(
                          currentTarget == item.href
                            ? 'text-white bg-green-600 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-600'
                            : 'text-black dark:text-white hover:bg-gray-100 dark:hover:bg-slate-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-medium leading-6 w-full'
                        )}
                      >
                        {currentTarget == item.href ?
                          <>
                            <item.selectedIcon
                              className={classNames(
                                'text-white bg-green-600 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-600',
                                'xl:h-6 xl:w-6 shrink-0 xl:m-0 md:m-auto md:h-7 md:w-7'
                              )}
                              aria-hidden="true"
                            />
                            {item.badge ? 
                              <IconBadge className="ml-[16px] xl:ml-[14px] mt-[-3px] py-[2px] px-[5px] border border-green-600 bg-white text-green-600">
                                { item.badge }
                              </IconBadge> : '' }
                          </>
                        :
                          <>
                            <item.icon
                              className={classNames(
                                'text-black dark:text-white',
                                'xl:h-6 xl:w-6 shrink-0 xl:m-0 md:m-auto md:h-7 md:w-7'
                              )}
                              aria-hidden="true"
                            />
                            {item.badge ? 
                              <IconBadge className="ml-[16px] xl:ml-[14px] mt-[-3px] py-[2px] px-[5px] border border-white dark:border-slate-900 bg-green-600 text-white">
                                { item.badge }
                              </IconBadge> : '' }
                          </>
                        }
                        <div className="md:hidden xl:block">
                          {item.name}
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              </li>

            </ul>
          </nav>
        </div>
      </div>

      <div className="fixed w-full h-[69px] overflow-hidden bottom-0 z-40 flex items-center backdrop-blur-sm bg-white dark:bg-slate-900 shadow-sm sm:px-6 md:hidden">
        <div className="flex justify-evenly w-full mx-0 md:mx-5 sm:mx-20 h-full">
          {navigation.map((item, index) => (
            item.displayMobile ? 

              <div key={item.name} 
                className={classNames(
                  currentTarget == item.href ? 'border-green-600' : 'border-white dark:border-slate-900',
                  "inline-flex flex-col w-20 pt-1 overflow-hidden group border-t-2"
                )}>
                <button 
                  type="button" 
                  className={classNames(
                    currentTarget == item.href ? 'text-green-600' : 'text-gray-400',
                    "inline-flex flex-col items-center justify-center group"
                  )}
                  onClick={() => navigateTo(item.href)}>
                    
                    {currentTarget == item.href ? 
                      <>
                        <item.selectedIcon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        {item.badge ? 
                          <IconBadge className="ml-[16px] mt-[-28px] py-[1px] px-[3px] border border-green-600 bg-white text-green-600">
                            { item.badge }
                          </IconBadge> : '' }
                      </>
                    :
                      <>
                        <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        {item.badge ? 
                          <IconBadge className="ml-[16px] mt-[-28px] py-[1px] px-[3px] border border-white dark:border-slate-900 bg-green-600 text-white">
                            { item.badge }
                          </IconBadge> : '' }
                      </>
                    }

                    <span className="text-xs">
                      {item.name}
                    </span>
                    
                </button>

              </div>

            : 
            
              ''

          ))}
        </div>
      </div>

    </div>
  )
}

export default Sidenav;