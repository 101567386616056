import { useState } from 'react';
import { useController } from 'react-hook-form';
import { Radio, RadioGroup } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const RadioGroupStack = ({
  control, // required
  name, //required
  options = [],
  group = true,
  className = ''
}) => {

  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control
  });
  
  const [selected, setSelected] = useState("");

  const handleClick = (event) => {
    const val = event.target.innerHTML.substring(0, event.target.innerHTML.indexOf("<span"));
    setSelected(val == selected ? "" : val);
  }

  return (     
    <fieldset>
      <RadioGroup control={control} name={field.name} value={field.value} className={classNames(className, "space-y-2")}>
        {options?.map((option, optionIdx) => (
          <Radio
            key={optionIdx}
            name={field.name}
            value={option}
            aria-label={option}
            className={classNames(
              group ? 'group' : '',
              'relative block cursor-pointer rounded-md border border-gray-300 dark:border-slate-800 bg-white dark:bg-slate-800 dark:text-white px-6 py-4 shadow-sm focus:outline-none data-[focus]:border-green-500 data-[focus]:ring-2 data-[focus]:ring-green-600 sm:flex sm:justify-between',
            )}
            onClick={handleClick}
          >
            { option }
            <span
              aria-hidden="true"
              className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-green-500"
            />
          </Radio>
        ))}
      </RadioGroup>
    </fieldset>
  );
}
 
export default RadioGroupStack;