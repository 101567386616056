import { useStoreState, useStoreActions } from 'easy-peasy';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

import SelectMenu from "../../ui/Form/SelectMenu/SelectMenu";
import {GENDER_OPTIONS} from '../../../services/GeneralInformationService';
import InputField from "../../ui/Form/InputField/InputField";

const WellnessFormPersonalInfo = () => {
  const account = useStoreState((state) => state.account.data);

  const personalSettings = useStoreState((state) => state.wellness.plan.personalSettings);
  const setPersonalSettings = useStoreActions((actions) => actions.wellness.plan.setPersonalSettings);

  const setCleanForm = useStoreActions((actions) => actions.wellness.plan.setCleanForm);

  const { 
    reset, 
    formState, 
    handleSubmit, 
    control, 
    setError, 
    getValues,
    formState: { isErrors, isValid, isDirty, isSubmitting } 
  } = useForm({
    defaultValues: {
      gender: null,
      age: null,
      heightFeet: null,
      heightInches: null,
      weightPounds: null
    },
    values: {
      ...personalSettings
    }
  });

  useEffect(() => {
    setCleanForm(formState.isValid);
  },[formState]);

  const updateSettings = async () => {
    if (formState.isValid) {
      await setPersonalSettings(getValues());
    }
  }

  return (
    <div>

      <div className="text-xl text-center font-bold leading-6 text-gray-900 dark:text-white pt-6 pb-3">
        Hi { account?.firstName }!
      </div>

      <div className="text-sm text-center font-light leading-6 text-gray-900 dark:text-white md:pb-2">
        <p>We're happy you're here.</p>
        <p>Let's get to know a little about you.</p>
      </div>

      <div className="text-left space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 dark:divide-slate-800 sm:py-0">

      <form onBlur={() => updateSettings()}>
      
          {/* Gender */}
          <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <div>
              <label
                htmlFor="gender"
                className="block text-sm font-medium leading-6 text-gray-800 dark:text-white sm:mt-1.5"
              >
                Gender
              </label>
            </div>
            <div className="sm:col-span-2">
              <SelectMenu
                name="gender"
                value={GENDER_OPTIONS[0]?.value}
                control={control}
                options={GENDER_OPTIONS}
                required
              />
            </div>
          </div>

          {/* Age */}
          <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <div>
              <label
                htmlFor="age"
                className="block text-sm font-medium leading-6 text-gray-800 dark:text-white sm:mt-1.5"
              >
                Age
              </label>
            </div>
            <div className="sm:col-span-2">
              <InputField
                  type="number"
                  name="age"
                  control={control}
                  required
              />
            </div>
          </div>

          {/* Height */}
          <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <div>
              <label
                htmlFor="height"
                className="block text-sm font-medium leading-6 text-gray-800 dark:text-white sm:mt-1.5"
              >
                Height
              </label>
            </div>
            <div className="flex gap-5 sm:col-span-2">
              <InputField
                type="number"
                name="heightFeet"
                control={control}
                trailingValue="ft"
                required
              />
              <InputField
                type="number"
                name="heightInches"
                control={control}
                trailingValue="in"
                required
              />
            </div>
          </div>

          {/* Weight */}
          <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <div>
              <label
                htmlFor="weight"
                className="block text-sm font-medium leading-6 text-gray-800 dark:text-white sm:mt-1.5"
              >
                Weight
              </label>
            </div>
            <div className="sm:col-span-2">
              <InputField
                type="number"
                name="weightPounds"
                helpText="It's OK to estimate. You can update this later."
                control={control}
                trailingValue="lbs"
                required
              />
            </div>
          </div>

        </form>

      </div>

    </div>
  );
}
 
export default WellnessFormPersonalInfo;