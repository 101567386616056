import React from 'react';

const CheckboxButton = ({ name, label, register, className = '' }) => {
  return (
    <div
      id={`checkbox-${name}`}
      className={`relative block cursor-pointer rounded-md bg-white dark:bg-slate-800 dark:text-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between ${className}`}
    >
      <label htmlFor={name} className="cursor-pointer">
        <input
          type="checkbox"
          id={name}
          {...register(name)}
          className="mr-2 bg-white dark:bg-slate-700 checked:bg-green-600 checked:hover:bg-green-600"
        />
        {label}
      </label>
    </div>
  );
};

export default CheckboxButton;
