import { useEffect } from 'react';

const IconBadge = ({
  children,
  className
}) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  useEffect(() => {
  }, [children]);

  return (
    <div className={classNames(
      "absolute leading-none rounded-full font-bold text-xs",
      className ? className : ''
     )}>
      { children }
    </div>
  );
}
 
export default IconBadge;