import { useStoreState, useStoreActions } from 'easy-peasy';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

import CheckboxButton from '../../ui/Form/Checkbox/CheckboxButton';

const WellnessFormGoalsWeight = () => {
  const goalSettings = useStoreState((state) => state.wellness.plan.goalSettings);
  const setGoalSettings = useStoreActions((actions) => actions.wellness.plan.setGoalSettings);
  const setCleanForm = useStoreActions((actions) => actions.wellness.plan.setCleanForm);

  const { 
    control,
    register, 
    formState, 
    watch,
    getValues,
    formState: { isValid }
  } = useForm({
    defaultValues: {
      targetWeight: {
        weightPounds: null,
        barriers: {
          lackOfTime: false,
          regimenToHardToFollow: false,
          didNotEnjoyFood: false,
          difficultToMakeFoodChoices: false,
          socialEatingAndEvents: false,
          foodCravings: false,
          lackOfProgress: false
        }
      },
      gainMuscle: false,
      muscleGainGoals: {
        tone: false,
        bulk: false,
        strength: false
      },
      modifyDiet: false,
      manageStress: false,
      increaseStepCount: false
    },
    values: {
      ...goalSettings
    }
  });

  // Watch all form values
  const watchedValues = watch();

  // Whenever any value changes, we update the clean form status
  useEffect(() => {
    setCleanForm(isValid);
  }, [isValid, watchedValues, setCleanForm]);

  // Function to update settings whenever a value changes
  const updateSettings = async () => {
    setGoalSettings(getValues());
  };

  return (
    <section>

      <div className="text-xl font-bold leading-6 text-gray-900 dark:text-white pt-6 pb-3 px-10">
        In the past, what have been your barriers to losing weight?
      </div>

      <div className="text-sm font-light leading-6 text-gray-900 dark:text-white pb-6 px-10">
        <p>Select all that apply.</p>
      </div>

      <form className="px-10 pt-3" onChange={() => updateSettings()}>

        <CheckboxButton
          name="targetWeight.barriers.lackOfTime"
          label="Lack of time"
          register={register}
          className="mb-2"
        />

        <CheckboxButton
          name="targetWeight.barriers.regimenToHardToFollow"
          label="The regimen was too hard to follow"
          register={register}
          className="mb-2"
        />

        <CheckboxButton
          name="targetWeight.barriers.didNotEnjoyFood"
          label="Did not enjoy the food"
          register={register}
          className="mb-2"
        />

        <CheckboxButton
          name="targetWeight.barriers.difficultToMakeFoodChoices"
          label="Difficult to make food choices"
          register={register}
          className="mb-2"
        />

        <CheckboxButton
          name="targetWeight.barriers.socialEatingAndEvents"
          label="Social eating and events"
          register={register}
          className="mb-2"
        />

        <CheckboxButton
          name="targetWeight.barriers.foodCravings"
          label="Food cravings"
          register={register}
          className="mb-2"
        />

        <CheckboxButton
          name="targetWeight.barriers.lackOfProgress"
          label="Lack of progress"
          register={register}
          className="mb-2"
        />

      </form>

    </section>
  );
}
 
export default WellnessFormGoalsWeight;