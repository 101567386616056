import { useStoreState, useStoreActions } from 'easy-peasy';
import { useEffect } from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

import LoadData from '../../features/LoadData/LoadData';
import Topnav from '../../features/Topnav/Topnav';
import Sidenav from '../../features/Sidenav/Sidenav';

import Login from '../../pages/Login/Login';
import Home from '../../pages/Home/Home';
import Wellness from '../../pages/Wellness/Wellness';
import Profile from '../../pages/Profile/Profile';
import Notifications from '../Notifications/Notifications';
import Messages from '../Messages/Messages';
import Settings from '../../pages/Settings/Settings';
import Register from '../Register/Register';
import Admin from '../Admin/Admin';
 
const Layout = () => {
  const account = useStoreState((state) => state.account.data);
  const setAccount = useStoreActions((actions) => actions.account.setAccount);

  const fetchAccountFromStorage = async () => {
    console.log("[LAYOUT] Account state is missing, checking local storage");

    const accountFromStorage = 
      localStorage.getItem("account") !== 'undefined' ? 
      JSON.parse(localStorage.getItem("account")) : null;

    if (accountFromStorage) {
      console.log("[LAYOUT] Account found in local storage, resetting state");
      setAccount(accountFromStorage);
    } else {
      console.log("[LAYOUT] Account not found in local storage, user must login");
    }
  }

  useEffect(() => {
    if (!account) {
      fetchAccountFromStorage();
    }
  }, [account]);

  return (
    <div className="w-full">
      {account ? (
        <>
          <LoadData account={ account } />
          <div className="block md:hidden">
            <Topnav />
          </div>
          <div>
            <div>
              <Sidenav />
            </div>
            <div className="md:pl-20 xl:pl-80 h-[calc(100vh-135px)] md:h-screen overflow-y-auto">
              <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/wellness" element={<Wellness />} />
                <Route path='/hello/:username' element={<Profile />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/messages" element={<Messages />} />
                { account.isAdmin && <Route path="/admin" element={<Admin />} /> }
                <Route path='/settings' element={<Settings />} />
                <Route path="*" element={<Navigate to="/home" />} />
              </Routes>
            </div>
          </div>
        </>
      ): (
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </div>
  );
}
 
export default Layout;