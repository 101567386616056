import { useStoreState, useStoreActions } from 'easy-peasy';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

import InputField from "../../ui/Form/InputField/InputField";
import CheckboxButton from '../../ui/Form/Checkbox/CheckboxButton';

const WellnessFormGoals = () => {
  const account = useStoreState((state) => state.account.data);
  const goalSettings = useStoreState((state) => state.wellness.plan.goalSettings);
  const setGoalSettings = useStoreActions((actions) => actions.wellness.plan.setGoalSettings);
  const setCleanForm = useStoreActions((actions) => actions.wellness.plan.setCleanForm);

  const { 
    control,
    register, 
    formState, 
    watch,
    getValues,
    formState: { isValid }
  } = useForm({
    defaultValues: {
      targetWeight: {
        weightPounds: null,
        barriers: {
          lackOfTime: false,
          regimenToHardToFollow: false,
          didNotEnjoyFood: false,
          difficultToMakeFoodChoices: false,
          socialEatingAndEvents: false,
          foodCravings: false,
          lackOfProgress: false
        }
      },
      gainMuscle: false,
      muscleGainGoals: {
        tone: false,
        bulk: false,
        strength: false
      },
      modifyDiet: false,
      manageStress: false,
      increaseStepCount: false
    },
    values: {
      ...goalSettings
    }
  });

  // Watch all form values
  const watchedValues = watch();

  // Whenever any value changes, we update the clean form status
  useEffect(() => {
    setCleanForm(isValid);
  }, [isValid, watchedValues, setCleanForm]);

  // Function to update settings whenever a value changes
  const updateSettings = async () => {
    setGoalSettings(getValues());
  };

  return (
    <section>
      <div className="text-xl text-center font-bold leading-6 text-gray-900 dark:text-white pt-6 pb-3">
        Thanks {account?.firstName}! Now for your goals.
      </div>

      <div className="text-sm text-center font-light leading-6 text-gray-900 dark:text-white pb-6">
        <p>Select up to 3 that are important to you, including one weight goal.</p>
      </div>

      <div className="text-left space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 dark:divide-slate-800 sm:py-0">

        <form onChange={() => updateSettings()}>

          {/* Goal Weight */}
          <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            
            <div>
              <label
                htmlFor="targetWeightPounds"
                className="block text-sm font-medium leading-6 text-gray-800 dark:text-white sm:mt-1.5"
              >
                Goal Weight
              </label>
            </div>
            <div className="sm:col-span-2">
              <InputField
                type="number"
                name="targetWeight.weightPounds"
                helpText="Don't worry. This doesn't affect your daily calorie goal and you can always change it later."
                control={control}
                trailingValue="lbs"
              />
            </div>

          <div className="sm:col-span-3">
            <CheckboxButton
              name="gainMuscle"
              label="Gain muscle"
              register={register}
            />
          </div>

          <div className="sm:col-span-3">
            <CheckboxButton
              name="modifyDiet"
              label="Modify my diet"
              register={register}
            />
          </div>

          <div className="sm:col-span-3">
            <CheckboxButton
              name="manageStress"
              label="Manage stress"
              register={register}
            />
          </div>

          <div className="sm:col-span-3">
            <CheckboxButton
              name="increaseStepCount"
              label="Increase step count"
              register={register}
            />
          </div>

        </div>

        </form>
      </div>
    </section>
  );
};

export default WellnessFormGoals;
