import { useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';

import {
  CheckBadgeIcon
} from '@heroicons/react/24/solid';

import accountImagePlaceholder from '../../../assets/images/user_image_placeholder.jpg';

const WhoToFollowWidget = () => { 
  const account = useStoreState((state) => state.account.data);
  const recommendedProfiles = useStoreState((state) => state.profile.recommendedProfiles);

  const navigate = useNavigate();

  const goToProfile = (username) => {
    navigate('/hello/' + username);
  }

  return (
    <div className="grid grid-cols-1 gap-2">
      
      { recommendedProfiles ? 
          
        recommendedProfiles.map((profile, profileIdx) => (
      
          profile.uid !== account.uid ?

            <div
              key={profileIdx}
              onClick={()=> goToProfile(profile.username)}
              className="relative flex items-center space-x-3 rounded-lg border border-gray-300 dark:border-slate-700 bg-transparent px-3 py-2 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
            >
              <div className="flex-shrink-0">
                <img 
                  alt="" 
                  src={ profile?.photoURL ? profile.photoURL : accountImagePlaceholder }
                  className="h-10 w-10 rounded-full" />
              </div>
              <div className="min-w-0 flex-1">
                <a href="#" className="focus:outline-none">
                  <span aria-hidden="true" className="absolute inset-0" />
                  <p className="flex text-sm font-medium text-gray-900 dark:text-white">
                    {profile.displayName}
                    {profile.isPremium ? 
                      <CheckBadgeIcon className="ml-1 m-auto h-4 w-4 shrink-0 text-green-600" />
                    : ''
                    }
                  </p>
                  <p className="truncate text-xs text-gray-500 dark:text-slate-400">@{profile.username}</p>
                </a>
              </div>
            </div>

          : ''

        ))

      :

        <div className="text-center">
          There are currenly no recommendations
        </div>

      }
          
    </div>
  )
}

export default WhoToFollowWidget;