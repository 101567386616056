const WellnessFormGoalsWeightEnd = ({ control }) => {
  return (
    <section>

      <div className="text-xl font-bold leading-6 text-gray-900 dark:text-white pt-6 pb-3 px-10">
        We get it. There are so many choices in the foods we eat, that it can be stressful to make those decisions.
      </div>

      <div className="text-sm font-light leading-6 text-gray-900 dark:text-white px-10">
        <p>
          Luckily we know all about managing potential pitfalls along the way 
          because we've helped people reach their goals.
        </p>
        <p className="pt-3 dark:text-white">
          Now, let's talk about your goal to gain muscle.
        </p>
      </div>

    </section>
  );
}
 
export default WellnessFormGoalsWeightEnd;