const WellnessFormGoalsWeightStart = ({ control }) => {
  return (
    <section>

      <div className="text-xl font-bold leading-6 text-gray-900 dark:text-white pt-6 pb-3 px-10">
        Great! You've just taken a big step on your journey.
      </div>

      <div className="text-sm font-light leading-6 text-gray-900 dark:text-white px-10">
        <p>
          Did you know that tracking your food is a scientifically proven method 
          to being successful? It's called “self-monitoring” and the more consistent you are, 
          the more likely you are to hit your goals.
        </p>
        <p className="pt-3 dark:text-white">
          Now, let's talk about your goal to lose weight.
        </p>
      </div>

    </section>
  );
}
 
export default WellnessFormGoalsWeightStart;