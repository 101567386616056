import { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { useParams } from 'react-router';

import accountImagePlaceholder from '../../assets/images/user_image_placeholder.jpg';
import Card from '../../components/ui/Card/Card';

import {
  CameraIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CheckBadgeIcon,
  CheckIcon,
  ClockIcon,
  PencilIcon,
  UserPlusIcon,
  XMarkIcon
} from '@heroicons/react/24/solid';

import Sidebar from '../../features/Sidebar/Sidebar';
import ProfileSearch from '../../features/ProfileSearch/ProfileSearch';
import Post from '../../features/Post/Post';
import Feed from '../../features/Feed/Feed';
import Button from '../../components/ui/Form/Button/Button';
import WhoToFollowWidget from '../../components/widgets/Social/WhoToFollowWidget';

const Profile = () => {
  const { username } = useParams();

  const account = useStoreState((state) => state.account.data);
  const myProfile = useStoreState((state) => state.profile.myProfile);
  
  const setMyProfile = useStoreActions((actions) => actions.profile.setMyProfile);
  const saveProfile = useStoreActions((actions) => actions.profile.saveProfile);
  
  const fetchProfileByUsername = useStoreActions((actions) => actions.profile.fetchProfileByUsername);
  const setCurrentProfile = useStoreActions((actions) => actions.profile.setCurrentProfile);
  const currentProfile = useStoreState((state) => state.profile.currentProfile);

  const saveFriendRequest = useStoreActions((actions) => actions.friendRequests.saveFriendRequest);
  const deleteFriendRequest = useStoreActions((actions) => actions.friendRequests.deleteFriendRequest);
  const receivedFriendRequests = useStoreState((state) => state.friendRequests.receivedFriendRequests);
  const sentFriendRequests = useStoreState((state) => state.friendRequests.sentFriendRequests);

  const [loadFeed, setLoadFeed] = useState(false);
  const [sentFriendRequestToCurrentProfile, setSentFriendRequestToCurrentProfile] = useState(false);
  const [receivedFriendRequestFromCurrentProfile, setReceivedFriendRequestFromCurrentProfile] = useState(false);
  const [friendsWithCurrentProfile, setFriendsWithCurrentProfile] = useState(false);

  const fetchProfileData = async () => {
    const currentProfile = await fetchProfileByUsername(username);
    await setCurrentProfile(currentProfile);

    setReceivedFriendRequestFromCurrentProfile(false);

    if (receivedFriendRequests?.length > 0 && currentProfile != null) {
      receivedFriendRequests.forEach((request, index) => {
        if (request.requestingAccountUid == currentProfile.uid) {
          setReceivedFriendRequestFromCurrentProfile(true);
        }
      });
    }

    setSentFriendRequestToCurrentProfile(false);

    if (sentFriendRequests?.length > 0 && currentProfile != null) {
      sentFriendRequests.forEach((request, index) => {
        if (request.receivingAccountUid == currentProfile.uid) {
          setSentFriendRequestToCurrentProfile(true);
        }
      });
    }

    setFriendsWithCurrentProfile(false);

    if (myProfile?.friends?.length > 0) {
      myProfile.friends.forEach((friend, index) => {
        console.log('friend.accountUid', friend.accountUid);
        console.log('currentProfile.uid', myProfile.uid);
        if (friend.accountUid == currentProfile.uid) {
          setFriendsWithCurrentProfile(true);
        }
      });
    }
  }

  useEffect(() => {
    fetchProfileData().catch('[LOAD DATA] An error occurred when fetching profile data', console.error);
  }, [username]);

  useEffect(() => {
    setLoadFeed(loadFeed);
  }, [currentProfile, loadFeed]);

  const onPostComplete = () => {
    setLoadFeed(true);
  }

  const onFeedRefereshComplete = () => {
    setLoadFeed(false);
  }

  const isMyProfile = () => {
    return myProfile && 
      currentProfile &&
      myProfile.uid == currentProfile.uid;
  }

  const sendRequest = async () => {
    await saveFriendRequest({
      requestingAccountUid: account.uid,
      receivingAccountUid: currentProfile.uid,
      createdAt: null
    });
    
    setSentFriendRequestToCurrentProfile(true);
  }

  const cancelRequest = async () => {
    await deleteFriendRequest({
      requestingAccountUid: account.uid,
      receivingAccountUid: currentProfile.uid
    });

    setSentFriendRequestToCurrentProfile(false);
  }

  const acceptRequest = async () => {
    let updatedMyProfile = myProfile;
    if (updatedMyProfile.friends?.length > 0) {
      updatedMyProfile.friends.push({
        accountUid: currentProfile.uid
      });
    } else {
      updatedMyProfile.friends = [{
        accountUid: currentProfile.uid
      }]
    }

    await saveProfile(updatedMyProfile);

    await setMyProfile(updatedMyProfile);

    await deleteFriendRequest({
      requestingAccountUid: currentProfile.uid,
      receivingAccountUid: account.uid
    });

    setSentFriendRequestToCurrentProfile(false);
    setReceivedFriendRequestFromCurrentProfile(false);
  }

  const rejectRequest = async () => {
    await deleteFriendRequest({
      requestingAccountUid: currentProfile.uid,
      receivingAccountUid: account.uid
    });

    setReceivedFriendRequestFromCurrentProfile(false);
  }

  const tabs = [
    { name: 'Posts', href: '#', current: true }
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  return (
    <div className="flex justify-between h-full">

      <div className="pb-4 px-0 md:px-4 lg:px-10 w-full overflow-y-auto">

        <div className="overflow-y-auto">
          
          <div className="bg-white rounded-b-xl overflow-hidden dark:bg-slate-900 text-black dark:text-white">
            <div className="h-40 lg:h-64 w-full bg-gray-200 dark:bg-slate-800 rounded-b-xl overflow-hidden">
              {/* <img alt="" src="https://scontent-iad3-2.xx.fbcdn.net/v/t39.30808-6/444929048_122097234944336120_4955679529508144888_n.jpg?stp=cp6_dst-jpg&_nc_cat=105&ccb=1-7&_nc_sid=cc71e4&_nc_ohc=782m8ufm1FsQ7kNvgHvnbWW&_nc_ht=scontent-iad3-2.xx&_nc_gid=AG_qgC6XVpuGDrYm_jaxORM&oh=00_AYC5TrxA4OFjoGtZB5U9GksR5yl4xZEc57vR5hKTWjMFVw&oe=66DC3AE7" className="h-48 w-full object-cover lg:h-64" /> */}
            </div>

            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              
              <div className="relative -mt-12 sm:-mt-16 sm:items-end sm:space-x-5">
                <div className="flex">
                  
                  {isMyProfile() ?
                    <>
                      <div className="absolute right-0 top-0 md:top-4">
                        <Button border={false} className="flex bg-white/60 dark:bg-slate-900/30 dark:text-white hover:dark:bg-slate-900/50">
                          <CameraIcon className="m-auto h-5 w-5" />
                          <span className="hidden lg:block ml-2">Edit cover photo</span>
                        </Button>
                      </div>
                      
                      <div className="absolute right-5 top-20">
                        <Button className="flex">
                          <PencilIcon className="m-auto h-4 w-4" />
                          <span className="hidden lg:block ml-2">Edit profile</span>
                        </Button>
                      </div>
                    </>
                  :
                    <div className="absolute right-0 top-20 flex gap-2">

                      { friendsWithCurrentProfile ?

                        <></>

                      : sentFriendRequestToCurrentProfile ?

                        <Button 
                          className="flex" 
                          onClick={() => cancelRequest()}>
                            <ClockIcon className="m-auto h-5 w-5" /> 
                            <span className="hidden lg:block ml-2">Cancel request</span>
                        </Button>

                      : receivedFriendRequestFromCurrentProfile ?
                        <>
                          <Button 
                            primary={true} 
                            className="flex" 
                            onClick={() => acceptRequest()}>
                              <CheckIcon className="m-auto h-5 w-5" />
                              <span className="hidden lg:block ml-2">Accept request</span>
                          </Button>

                          <Button 
                            warn={true} 
                            className="flex" 
                            onClick={() => rejectRequest()}>
                              <XMarkIcon className="m-auto h-5 w-5" />
                              <span className="hidden lg:block ml-2">Reject request</span>
                          </Button>
                        </>

                      :
                        <Button 
                          primary={true} 
                          className="flex" 
                          onClick={() => sendRequest()}>
                            <UserPlusIcon className="m-auto h-5 w-5" />
                            <span className="hidden lg:block ml-2">Add friend</span>
                        </Button>
                      }

                      <Button className="flex">
                        <ChatBubbleOvalLeftEllipsisIcon className="m-auto h-5 w-5" />
                        <span className="hidden lg:block ml-2">Message</span>
                      </Button>
                    </div>
                  }
                  
                  <div className="relative h-24 w-24 sm:h-32 sm:w-32">
                    <div className="rounded-full ring-4 ring-white dark:ring-slate-950 overflow-hidden">
                      <img
                        alt=""
                        src={ currentProfile?.photoURL ? currentProfile.photoURL : accountImagePlaceholder }
                        className="object-cover w-full"
                      />
                    </div>

                    {isMyProfile() ?
                      <button className="absolute flex shadow bottom-0 md:bottom-1 right-0 rounded-full bg-gray-200 dark:bg-slate-700 p-1.5">
                        <CameraIcon className="m-auto h-5 w-5 md:h-6 md:w-6" />
                      </button>
                    : '' }

                  </div>

                </div>
                <div className="mt-3 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                  <div className="min-w-0 flex-1 sm:hidden 2xl:block">
                    <div className="flex">
                      <h1 className="truncate text-2xl font-bold text-gray-900 dark:text-white">
                        {currentProfile?.displayName}
                      </h1>
                      {currentProfile?.isPremium ? 
                        <CheckBadgeIcon className="m-auto ml-1 h-5 w-5 shrink-0 text-green-600" />
                      : ''
                      }
                    </div>
                    <div className="truncate text-md text-gray-900 dark:text-white">
                      @{currentProfile?.username}
                    </div>
                    <div className="truncate text-sm mt-3 text-gray-900 dark:text-white">
                      {currentProfile?.description}
                    </div>
                    <div className="truncate flex gap-3 text-sm mt-3 font-light text-gray-900 dark:text-white">
                      <div>
                        <span className="mr-1 font-bold">{ currentProfile?.friends ? 1 : 0 }</span>
                        Friends
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-3 hidden min-w-0 flex-1 sm:block 2xl:hidden">
                <div className="flex">
                  <h1 className="truncate text-2xl font-bold text-gray-900 dark:text-white">
                    {currentProfile?.displayName}
                  </h1>
                  {currentProfile?.isPremium ? 
                    <CheckBadgeIcon className="m-auto ml-1 h-5 w-5 shrink-0 text-green-600" />
                  : ''
                  }
                </div>
                <div className="truncate text-sm text-gray-900 dark:text-white">
                  @{currentProfile?.username}
                </div>
                <div className="truncate text-sm mt-3 text-gray-900 dark:text-white">
                  {currentProfile?.description}
                </div>
                <div className="truncate flex gap-3 text-sm mt-3 font-light text-gray-900 dark:text-white">
                  <div>
                    <span className="mr-1 font-bold">{ currentProfile?.friends ? currentProfile.friends.length : 0 }</span>
                    Friends
                  </div>
                </div>
              </div>

            </div>
          
            <div className="border-b border-gray-200 dark:border-slate-700">
              <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                  {tabs.map((tab) => (
                    <a
                      key={tab.name}
                      href={tab.href}
                      aria-current={tab.current ? 'page' : undefined}
                      className={classNames(
                        tab.current
                          ? 'border-green-600 text-gray-900 dark:text-white'
                          : 'border-transparent text-gray-500 dark:text-slate-400 hover:border-gray-300 dark:hover:border-slate-400 hover:text-gray-700 dark:hover:text-slate-300',
                        'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
                      )}
                    >
                      {tab.name}
                    </a>
                  ))}
                </nav>
              </div>
            </div>

          </div>

          <div className="mt-1 md:mt-3">
            {isMyProfile() ?
              <Post onComplete={onPostComplete} />
            : '' }
            
            <div className="mt-1 md:mt-3">
              <Feed accountUid={currentProfile?.uid} refresh={loadFeed} onLoadComplete={onFeedRefereshComplete} />
            </div>
          </div>

        </div>
      
      </div>
      
      <Sidebar className="hidden md:block md:min-w-[350px] p-3 flex flex-col space-y-3">
        <ProfileSearch />

        <Card header="Explore">
          Content here
        </Card>

        <Card header="Who to Follow">
          <WhoToFollowWidget />
        </Card>
      </Sidebar>

    </div>

  )
}

export default Profile;