import { useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';
import { MagnifyingGlassIcon, EnvelopeIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import logo from '../../assets/images/logo.png';
import Button from '../../components/ui/Form/Button/Button';
import IconBadge from '../../components/ui/IconBadge/IconBadge';

const Topnav = () => {
  const account = useStoreState((state) => state.account.data);

  const navigate = useNavigate();
  const navigateTo = (href) => {
    navigate(href);
  };
  
  return (
    <div className="w-full bg-white dark:bg-slate-900">
      
      <div className="flex h-16 shrink-0 items-center pl-4 justify-between">
        <div className="flex">
          <img
            className="h-8 w-auto md:m-auto xl:m-0"
            src={logo}
            alt="HelloLife"
          />
          <div className="ml-2 text-xl text-slate-800 dark:text-white md:hidden xl:block">HelloLife</div>
        </div>
        <div className="flex gap-2 pr-2">
          <Button className="bg-transparent dark:bg-slate-900 hover:cursor-pointer">
            <MagnifyingGlassIcon className="h-5 w-5" />
          </Button>
          <Button className="bg-transparent dark:bg-slate-900 hover:cursor-pointer" onClick={() => navigateTo('/messages')}>
            <EnvelopeIcon className="h-5 w-5" />
            <IconBadge className="ml-[12px] mt-[-25px] py-[1px] px-[3px] border border-white dark:border-slate-900 bg-green-600 text-white">
              2
            </IconBadge>
          </Button>
          {account.isAdmin ?
            <Button className="bg-transparent dark:bg-slate-900 hover:cursor-pointer" onClick={() => navigateTo('/admin')}>
              <GlobeAltIcon className="h-5 w-5" />
            </Button>
          : '' }
        </div>
      </div>

    </div>
  )
}

export default Topnav;