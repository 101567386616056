import { action, thunk } from 'easy-peasy';
import { collection, addDoc, getDocs, query, orderBy, setDoc, doc, deleteDoc } from 'firebase/firestore';
import { firestore } from '../../../../firebase';

import { getDateTime } from '../../../../services/DateTimeService';

const activity = {
  data: null,

  setActivities: action((state, activities) => {
    state.data = activities;
  }),

  /* 
    Save activity
  */
  saveActivity: action(async (actions, activity) => {
    const account = JSON.parse(localStorage.getItem("account"));

    activity.createdAt = getDateTime();

    console.log('[WELLNESS ACTIVITY] Saving activity');
    // const wellnessDocRef = doc(firestore, account.uid, "wellness", "activities");
    const activitiesCollectionRef = collection(firestore, "accounts", account.uid, "wellness", "fitnessPlan", "activities");
    const activityDocRef = await addDoc(activitiesCollectionRef, activity);
    activity.uid = activityDocRef.id;

    await setDoc(activityDocRef, activity);
  }),

  /**
   * Delete a activity
   */
  deleteActivity: action(async (actions, activityUid) => {
    const activitiesCollectionRef = collection(firestore, "accounts", account.uid, "wellness", "fitnessPlan", "activities");
    const activityDocRef = doc(activitiesCollectionRef, activityUid);

    await deleteDoc(activityDocRef);
  }),

  /*
    Get the activity
  */
  fetchActivities: thunk(async (actions, accountId, state) => {
    console.log('[WELLNESS ACTIVITY] Fetching activities');
    const activitiesCollectionRef = collection(firestore, "accounts", accountId, "wellness", "fitnessPlan", "activities");
    const q = query(activitiesCollectionRef, orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    const activities = querySnapshot.docs.map(doc => doc.data());

    if (activities) {
      console.log('[WELLNESS ACTIVITY] Found Wellness Activities for Account with ID ' + accountId);
    } else {
      console.log('[WELLNESS ACTIVITY] Missing Wellness Activitie for Account with ID ' + accountId);
    }

    return activities;
  })

};

export default activity;
