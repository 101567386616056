import { useStoreState } from 'easy-peasy';
import Badge from '../../ui/Badge/Badge';

const WorkoutWidget = () => { 
  const fitnessPlan = useStoreState((state) => state.wellness.plan.fitnessPlan);

  return (
    <section>

      <ol className="divide-y divide-gray-200 text-sm leading-6 dark:divide-slate-800">
        {fitnessPlan?.days?.map((day, dayIdx) => (

          <li key={dayIdx} className="py-4 flex">
            <time dateTime="2022-01-17" className="w-[100px] flex-none font-semibold text-green-600">
              { day.day }
            </time>

            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-4">

              {day.workouts?.map((workout, workoutIdx) => (

                <div key={workoutIdx}>
                  <div className="text-gray-700 dark:text-white">
                    { workout.title }
                  </div>
                  <div className="text-gray-500 dark:text-slate-400">
                    { workout.description }
                  </div>
                  
                  {workout.sets > 0 && workout.reps > 0 ?
                    <div className="text-gray-500 dark:text-slate-400">
                      Sets: { workout.sets } | Reps: { workout.reps }
                    </div>
                  : '' }

                  <div className="text-gray-500 dark:text-slate-400">
                    Estimated Calorie Burn: { workout.estimatedCalorieBurn } Cals
                  </div>
                </div>
                
              ))}

            </div>
            
          </li>
        
        ))}

      </ol>

    </section>
  )
}

export default WorkoutWidget;