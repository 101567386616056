import { action, thunk } from 'easy-peasy';
import { doc, collection, setDoc, deleteDoc, getDocs, query, orderBy, where } from 'firebase/firestore';
import { firestore } from '../../../../firebase';

import { getDateTime } from '../../../../services/DateTimeService';

const friendRequests = {
  id: null,

  receivedFriendRequests: null,
  sentFriendRequests: null,

  /*
    Set received friend request
  */
  setReceivedFriendRequests: action((state, payload) => {
    state.receivedFriendRequests = payload;
  }),

  /*
    Set sent friend request
  */
  setSentFriendRequests: action((state, payload) => {
    state.sentFriendRequests = payload;
  }),

  /* 
    Save friend request
  */
  saveFriendRequest: action(async (actions, request) => {
    request.createdAt = getDateTime();

    console.log('[FRIEND REQUEST] Saving friend request to receiving account');
    await setDoc(doc(firestore, "accounts", request.receivingAccountUid, "notifications", "friendRequests", "received", request.requestingAccountUid), request);

    console.log('[FRIEND REQUEST] Saving friend request to requesting account');
    await setDoc(doc(firestore, "accounts", request.requestingAccountUid, "notifications", "friendRequests", "sent", request.receivingAccountUid), request);
  }),

  /**
   * Delete a friend request
   */
  deleteFriendRequest: action(async (actions, request) => {
    console.log('[FRIEND REQUEST] Deleting sent friend request');
    let docRef = doc(firestore, "accounts", request.requestingAccountUid, "notifications", "friendRequests", "sent", request.receivingAccountUid);
    await deleteDoc(docRef);

    console.log('[FRIEND REQUEST] Deleting received friend request');
    docRef = doc(firestore, "accounts", request.receivingAccountUid, "notifications", "friendRequests", "received", request.requestingAccountUid);
    await deleteDoc(docRef);
  }),

  /*
    Get received friend requests
  */
  fetchReceivedFriendRequests: thunk(async (actions, accountId, state) => {
    console.log('[FRIEND REQUEST] Fetching received friend requests');
    const collectionRef = collection(firestore, "accounts", accountId, "notifications", "friendRequests", "received");
    const q = query(collectionRef, orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    const docs = querySnapshot.docs.map(doc => doc.data());

    if (docs) {
      console.log('[FRIEND REQUEST] Found received friend requests for account with ID ' + accountId);
    } else {
      console.log('[FRIEND REQUEST] Missing received friend requests for account with ID ' + accountId);
    }

    return docs;
  }),

  /*
    Get sent friend requests
  */
  fetchSentFriendRequests: thunk(async (actions, accountId, state) => {
    console.log('[FRIEND REQUEST] Fetching sent friend requests');
    const collectionRef = collection(firestore, "accounts", accountId, "notifications", "friendRequests", "sent");
    const q = query(collectionRef, orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    const docs = querySnapshot.docs.map(doc => doc.data());

    if (docs) {
      console.log('[FRIEND REQUEST] Found sent friend requests for account with ID ' + accountId);
    } else {
      console.log('[FRIEND REQUEST] Missing sent friend requests for account with ID ' + accountId);
    }

    return docs;
  })

};

export default friendRequests;