import { useStoreActions } from 'easy-peasy';
import { useEffect, useState } from 'react';

import Loading from "../../components/ui/Loading/Loading";

const LoadData = ({account}) => {
  const [loading, setLoading] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState("Loading");

  const fetchWellnessPersonalSettings = useStoreActions((actions) => actions.wellness.plan.fetchPersonalSettings);
  const setWellnessPersonalSettings = useStoreActions((actions) => actions.wellness.plan.setPersonalSettings);

  const fetchWellnessGoalSettings = useStoreActions((actions) => actions.wellness.plan.fetchGoalSettings);
  const setWellnessGoalSettings = useStoreActions((actions) => actions.wellness.plan.setGoalSettings);

  const fetchWellnessDietarySettings = useStoreActions((actions) => actions.wellness.plan.fetchDietarySettings);
  const setWellnessDietarySettings = useStoreActions((actions) => actions.wellness.plan.setDietarySettings);

  const fetchWellnessDietaryPlan = useStoreActions((actions) => actions.wellness.plan.fetchDietaryPlan);
  const setWellnessDietaryPlan = useStoreActions((actions) => actions.wellness.plan.setDietaryPlan);

  const fetchWellnessFitnessPlan = useStoreActions((actions) => actions.wellness.plan.fetchFitnessPlan);
  const setWellnessFitnessPlan = useStoreActions((actions) => actions.wellness.plan.setFitnessPlan);

  const fetchWellnessActivities = useStoreActions((actions) => actions.wellness.activity.fetchActivities);
  const setWellnessActivities = useStoreActions((actions) => actions.wellness.activity.setActivities);

  const fetchProfileByUid = useStoreActions((actions) => actions.profile.fetchProfileByUid);
  const setMyProfile = useStoreActions((actions) => actions.profile.setMyProfile);
  const saveProfile = useStoreActions((actions) => actions.profile.saveProfile);

  const fetchProfiles = useStoreActions((actions) => actions.profile.fetchProfiles);
  const setRecommendedProfiles = useStoreActions((actions) => actions.profile.setRecommendedProfiles);

  const fetchSentFriendRequests = useStoreActions((actions) => actions.friendRequests.fetchSentFriendRequests);
  const setSentFriendRequests = useStoreActions((actions) => actions.friendRequests.setSentFriendRequests);

  const fetchReceivedFriendRequests = useStoreActions((actions) => actions.friendRequests.fetchReceivedFriendRequests);
  const setReceivedFriendRequests = useStoreActions((actions) => actions.friendRequests.setReceivedFriendRequests);

  const fetchData = async () => {
    if (account?.uid) {
      setLoading(true);
  
      try {
        console.log('[LOAD DATA] Fetching wellness personal settings for account', account?.uid);
        setLoadingLabel("Loading wellness plan");
        const wellnessPlanPersonalSettings = await fetchWellnessPersonalSettings(account?.uid);
        await setWellnessPersonalSettings(wellnessPlanPersonalSettings);

        console.log('[LOAD DATA] Fetching wellness goal settings for account', account?.uid);
        const wellnessPlanGoalSettings = await fetchWellnessGoalSettings(account?.uid);
        await setWellnessGoalSettings(wellnessPlanGoalSettings);

        console.log('[LOAD DATA] Fetching wellness dietary settings for account', account?.uid);
        const wellnessPlanDietarySettings = await fetchWellnessDietarySettings(account?.uid);
        await setWellnessDietarySettings(wellnessPlanDietarySettings);

        console.log('[LOAD DATA] Fetching wellness dietary plan for account', account?.uid);
        const wellnessDietaryPlan = await fetchWellnessDietaryPlan(account?.uid);
        await setWellnessDietaryPlan(wellnessDietaryPlan);

        console.log('[LOAD DATA] Fetching wellness fitness plan for account', account?.uid);
        const wellnessFitnessPlan = await fetchWellnessFitnessPlan(account?.uid);
        await setWellnessFitnessPlan(wellnessFitnessPlan);

        console.log('[LOAD DATA] Fetching wellness activity data for account', account?.uid);
        const wellnessActivities = await fetchWellnessActivities(account?.uid);
        await setWellnessActivities(wellnessActivities);

        console.log('[LOAD DATA] Fetching my profile data for account', account?.uid);
        setLoadingLabel("Loading your profile");
        const myProfile = await fetchProfileByUid(account?.uid);
        if (!myProfile) {
          await createProfile(account);
        } else {
          await setMyProfile(myProfile);
        }

        setLoadingLabel("Loading account activity");
        console.log('[LOAD DATA] Fetching received friend requests for account', account?.uid);
        const receivedFriendRequests = await fetchReceivedFriendRequests(account?.uid);
        await setReceivedFriendRequests(receivedFriendRequests);

        console.log('[LOAD DATA] Fetching sent friend requests for account', account?.uid);
        const sentFriendRequests = await fetchSentFriendRequests(account?.uid);
        await setSentFriendRequests(sentFriendRequests);

        console.log('[LOAD DATA] Fetching recommended profiles');
        setLoadingLabel("Loading recommendations");
        const recommendedProfiles = await fetchProfiles();
        await setRecommendedProfiles(recommendedProfiles);
      } catch (error) {
        console.error('[LOAD DATA] An error occurred', error);
      } finally {
        setLoading(false);
      }
    }
  }

  const createProfile = async (account) => {
    console.log('[LOGIN] Creating new profile');
    const profile = await saveProfile({
      uid: account.uid,
      displayName: account.firstName + ' ' + account.lastName,
      description: null,
      username: (account?.firstName?.trim() + account?.lastName?.trim()).toLowerCase(),
      photoURL: account.photoURL,
      isPremium: account.isPremium
    });

    await setMyProfile(profile);
  }

  useEffect(() => {
    fetchData()
    .catch('[LOAD DATA] An error occurred when fetching the data', console.error);
  }, [account]);

  useEffect(() => {
    const interval = setInterval(async () => {
      console.log('[LOAD DATA] Fetching received friend requests for account', account?.uid);
      setLoadingLabel("Loading notifications");
      const receivedFriendRequests = await fetchReceivedFriendRequests(account?.uid);
      await setReceivedFriendRequests(receivedFriendRequests);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={loading ? 'block' : 'hidden' }>
      <Loading className="h-screen" label={loadingLabel} />
    </div>
  );
}
 
export default LoadData;