import { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

import Loading from '../../components/ui/Loading/Loading';

import SocialCard from "../SocialCard/SocialCard";

const Feed = ({
  accountUid,
  refresh,
  onLoadComplete
}) => {
  const [loading, setLoading] = useState(false);

  const [completedPosts, setCompletedPosts] = useState([]);

  const posts = useStoreState((state) => state.post.data);
  const fetchPosts = useStoreActions((actions) => actions.post.fetchPosts);
  const setPosts = useStoreActions((actions) => actions.post.setPosts);

  const fetchAccount = useStoreActions((actions) => actions.account.fetchAccount);

  const fetchData = async () => {
    setLoading(true);

    setCompletedPosts([]);

    const posts = await fetchPosts(accountUid);

    const tempPosts = [];
    posts.forEach(async (post) => {
      await fetchAccount(post.accountUid).then(account => {
        tempPosts?.push({
          uid: post.uid,
          accountUid: post.accountUid,
          createdAt: post.createdAt,
          post: post.post,
          displayName: account.firstName + ' ' + account.lastName,
          photoURL: account.photoURL,
          premiumAccount: account.isPremium
        });
      });

      setCompletedPosts(tempPosts);
    });

    onLoadComplete();

    setLoading(false);
  }

  useEffect(() => {
    fetchData()
    .then()
    .catch('[FEED] An error occurred when fetching post data', console.error);
  }, [refresh, accountUid]);

  return (
    <div id="posts">

      {loading ? 
        
        <Loading label="Loading Feed" />          
        
      : 

        <div className="grid gap-y-1 md:gap-y-3">
          {completedPosts?.map((post, postIdx) => (
            <SocialCard 
              key={postIdx} 
              postUid={post.uid}
              accountUid={post.accountUid}
              name={post.displayName}
              premiumAccount={post.premiumAccount}
              photoURL={post.photoURL}
              createdAt={post.createdAt}
            >
              <div className="mb-3">
                {post.post}
              </div>
            </SocialCard>
          ))}
        </div>
        
      }

    </div>
  )
}

export default Feed;