import { useState } from 'react';

import AdsComponent from "../../ads/AdsComponent";
import Modal from "../../ui/Modal/Modal";
import Button from "../../ui/Form/Button/Button";

import Premium from '../../../pages/Premium/Premium';

import { RocketLaunchIcon } from '@heroicons/react/24/outline';

const WellnessFormAd = () => {
  const [isPremiumModalOpen, setIsPremiumModalOpen] = useState(false);

  const onPrmeiumClose = () => {
    setIsPremiumModalOpen(false);
  }

  return (
    <>
      <section>
        
        <div className="text-xl text-center font-bold leading-6 text-gray-900 dark:text-white pt-6 pb-3">
          <div className="flex">
            <span className="ml-auto mr-2">Consider upgrading to premium</span>
            <RocketLaunchIcon className="h-5 w-5 mr-auto" aria-hidden="true" />
          </div>
        </div>

        <div className="text-sm text-center font-light leading-6 text-gray-900 dark:text-white pb-6">
          <p>Ads keep us running. Consider upgrading to premium to remove ads.</p>
        </div>

        <div className="px-10 pt-3 dark:text-white">
          <AdsComponent dataAdSlot='4850685890' adFormat='vertical' />
        </div>

        <div className="mt-5 text-center">
          <Button
            accent={true}
            onClick={() => setIsPremiumModalOpen(true)}>
            <div className="flex gap-2">
              <span>Go Premium</span>
              <RocketLaunchIcon className="h-5 w-5 mr-auto" aria-hidden="true" />
            </div>
          </Button>
          </div>

      </section>

      <Modal
        isOpen={isPremiumModalOpen}
        onClose={onPrmeiumClose}>
          
        <Premium />

      </Modal>
    </>
  );
}
 
export default WellnessFormAd;