import { useStoreState, useStoreActions } from 'easy-peasy';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

import CheckboxButton from '../../ui/Form/Checkbox/CheckboxButton';

const WellnessFormGoalsMuscle = () => {
  const goalSettings = useStoreState((state) => state.wellness.plan.goalSettings);
  const setGoalSettings = useStoreActions((actions) => actions.wellness.plan.setGoalSettings);
  const setCleanForm = useStoreActions((actions) => actions.wellness.plan.setCleanForm);

  const { 
    control,
    register, 
    formState, 
    watch,
    getValues,
    formState: { isValid }
  } = useForm({
    defaultValues: {
      targetWeight: {
        weightPounds: null,
        barriers: {
          lackOfTime: false,
          regimenToHardToFollow: false,
          didNotEnjoyFood: false,
          difficultToMakeFoodChoices: false,
          socialEatingAndEvents: false,
          foodCravings: false,
          lackOfProgress: false
        }
      },
      gainMuscle: false,
      muscleGainGoals: {
        tone: false,
        bulk: false,
        strength: false
      },
      modifyDiet: false,
      manageStress: false,
      increaseStepCount: false
    },
    values: {
      ...goalSettings
    }
  });

  // Watch all form values
  const watchedValues = watch();

  // Whenever any value changes, we update the clean form status
  useEffect(() => {
    setCleanForm(isValid);
  }, [isValid, watchedValues, setCleanForm]);

  // Function to update settings whenever a value changes
  const updateSettings = async () => {
    setGoalSettings(getValues());
  };

  return (
    <section>

      <div className="text-xl font-bold leading-6 text-gray-900 dark:text-white pt-6 pb-3 px-10">
        What results do you want to achieve from gaining muscle?
      </div>

      <div className="text-sm font-light leading-6 text-gray-900 dark:text-white pb-6 px-10">
        <p>Select all that apply.</p>
      </div>

      <form className="px-10 pt-3" onChange={() => updateSettings()}>

        <CheckboxButton
          name="muscleGainGoals.tone"
          label="Tone up - you want visible muscles with as little mass as possible"
          register={register}
          className="mb-2"
        />

        <CheckboxButton
          name="muscleGainGoals.bulk"
          label="Bulk up - you want large, well-defined muscles, with a low percentage of body fat"
          register={register}
          className="mb-2"
        />

        <CheckboxButton
          name="muscleGainGoals.strength"
          label="Get strong - you want to lift the maximum amount of weight and are not concerned with body fat or muscle definition"
          register={register}
        />

      </form>

    </section>
  );
}
 
export default WellnessFormGoalsMuscle;