import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStoreState, useStoreActions } from 'easy-peasy';
import {useNavigate} from 'react-router-dom';
import {
    createUserWithEmailAndPassword,
    getAuth
} from 'firebase/auth';
import logo from '../../assets/images/logo.png';
import InputField from '../../components/ui/Form/InputField/InputField';
import Button from '../../components/ui/Form/Button/Button';
 
const Register = () => {
    const auth = getAuth();

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState();

    const fetchAccount = useStoreActions((actions) => actions.account.fetchAccount);
    const saveAccount = useStoreActions((actions) => actions.account.saveAccount);
    const setAccount = useStoreActions((actions) => actions.account.setAccount);
    const account = useStoreState((state) => state.account.data);
    const saveProfile = useStoreActions((actions) => actions.profile.saveProfile);

    const { reset, formState, handleSubmit, getValues, control, setError } = useForm({
        values: {
            ...account
        }
    });

    const onRegister = async (e) => {
        e.preventDefault();

        console.log('[LOGIN] Registering new account', getValues("email"));

        await createUserWithEmailAndPassword(auth, getValues("email"), getValues("password"))
            .then(async (userCredential) => {
            
            await saveAccount({
                uid: userCredential.user.uid,
                email: userCredential.user.email,
                firstName: getValues("firstName"),
                lastName: getValues("lastName"),
                photoURL: null,
                providerData: null,
                isPremium: false,
                timezone: null
            });

            await fetchAccount(userCredential.user.uid).then(async account => {
                localStorage.setItem('account', JSON.stringify(account));
                await createProfile(account);
                await setAccount(account);
                navigate('/');
            });

        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
        });
    }

    const createProfile = async (account) => {
        console.log('[LOGIN] Creating new profile');
        await saveProfile({
            uid: account.uid,
            displayName: account.firstName + ' ' + account.lastName,
            description: null,
            username: account?.firstName?.trim() + account?.lastName?.trim(),
            photoURL: account.photoURL,
            isPremium: account.isPremium
        });
    }
 
    return(
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        src={logo}
                        alt="HelloLife"
                        className="mx-auto h-10 w-auto"
                    />
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-slate-400">
                        Create an account
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    
                    <div className="bg-white dark:bg-slate-900 px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        
                        <form action="#" method="POST" className="space-y-6">

                            <div>
                                <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-800 dark:text-white">
                                First Name
                                </label>
                                <div className="mt-2">
                                    <InputField
                                        id="firstName"
                                        name="firstName"
                                        autoComplete="current-firstName"
                                        control={control}
                                        required
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-800 dark:text-white">
                                Last Name
                                </label>
                                <div className="mt-2">
                                    <InputField
                                        id="lastName"
                                        name="lastName"
                                        autoComplete="current-lastName"
                                        control={control}
                                        required
                                    />
                                </div>
                            </div>
                            
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-800 dark:text-white">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <InputField
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="current-email"
                                        control={control}
                                        required
                                        onChange={(e)=>setEmail(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-800 dark:text-white">
                                    Password
                                </label>
                                <div className="mt-2">
                                    <InputField
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        control={control}
                                        required
                                        onChange={(e)=>setPassword(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div>
                                <Button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                    onClick={onRegister}
                                    primary={true}
                                    disabled={!formState.isValid}
                                >
                                    Create account
                                </Button>
                            </div>

                            {errorMessage ? 
                                <div className="w-full p-2 rounded-md bg-red-100 dark:bg-red-700 dark:text-white text-sm text-center">
                                    {errorMessage}
                                </div>
                            : '' }

                        </form>

                    </div>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Already a member?{' '}
                        <a href="/login" className="font-semibold leading-6 text-green-600 hover:text-green-500">
                            Sign in now
                        </a>
                    </p>

                </div>

            </div>
        </>
    )
}
 
export default Register;