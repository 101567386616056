import { useStoreState } from 'easy-peasy';

import Card from '../../components/ui/Card/Card';
import ProgressBar from '../../components/ui/ProgressBar/ProgressBar';

const WellnessCards = () => {
  const personalSettings = useStoreState((state) => state.wellness.plan.personalSettings);
  const goalSettings = useStoreState((state) => state.wellness.plan.goalSettings);
  const dietaryPlan = useStoreState((state) => state.wellness.plan.dietaryPlan);
  const activities = useStoreState((state) => state.wellness.activity.data);

  const startingWeight = () => {
    return personalSettings.weightPounds;
  }

  const goalWeight = () => {
    return goalSettings.targetWeight.weightPounds;
  }

  const mostRecentWeight = () => {
    return activities?.length > 0 ? activities[0].details.weightPounds : startingWeight();
  }

  const poundsToTargetWeight = () => {
    return mostRecentWeight() - goalWeight();
  }

  const targetWeightProgressPercentage = () => {
    return ((startingWeight() - mostRecentWeight()) / (startingWeight() - goalWeight())) * 100;
  }

  return ( 
    <div className="lex flex-col space-y-4"> 
       
      <Card header={'Target Weight'}>
        <ProgressBar className="rounded-full" progress={
          [{
            percentage: targetWeightProgressPercentage(),
            className: 
              targetWeightProgressPercentage() <= 33 ? 'h-4 bg-red-500' :
              targetWeightProgressPercentage() > 33 && targetWeightProgressPercentage() <= 66 ? 'h-4 bg-orange-500' :
              'h-4 bg-green-500' 
          }]
        } />

        <div className="text-xs">
          { poundsToTargetWeight() } pounds to target weight of { goalWeight() } pounds
        </div>
      </Card>

      <Card header={'Calories Today'}>
        <ProgressBar className="rounded-full" progress={
          [{
            percentage: 0,
            className: 'h-4 bg-green-500'
          },
          {
            percentage:0,
            className: 'h-4 bg-orange-500'
          }]
        } />

        <div className="text-xs">
          0 consumed, 0 burned, { dietaryPlan.calorieAllotmentPerDay } remaining
        </div>
      </Card>

      <Card header={'Awards Earned'}>
        Keep working hard to earn awards!
      </Card>

    </div>
  )
}
 
export default WellnessCards;