import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { auth } from '../../firebase';

import { useStoreState, useStoreActions } from 'easy-peasy';
import accountImagePlaceholder from '../../assets/images/user_image_placeholder.jpg';
import InputField from '../../components/ui/Form/InputField/InputField';
import SelectMenu from '../../components/ui/Form/SelectMenu/SelectMenu';
import {TIMEZONE_OPTIONS} from '../../services/GeneralInformationService';
import Button from '../../components/ui/Form/Button/Button';
import Notification from '../../components/ui/Notification/Notification';
import Card from '../../components/ui/Card/Card';

const Settings = () => {
    const account = useStoreState((state) => state.account.data);
    const setAccount = useStoreActions((actions) => actions.account.setAccount);
    const saveAccount = useStoreActions((actions) => actions.account.saveAccount);

    const setMyProfile = useStoreActions((actions) => actions.profile.setMyProfile);

    const [notification, setNotification] = useState(null);

    const { reset, formState, handleSubmit, getValues, control, setError } = useForm({
        values: {
            ...account
        }
    });

    const onSave = async (e) => {
        e.preventDefault();

        const updatedAccount = saveAccount({
            uid: account.uid,
            firstName: getValues('firstName'),
            lastName: getValues('lastName'),
            // email: account?.providerData ? account.email : getValues('email'),
            email: account.email,
            timezone: getValues('timezone'),
            photoURL: account.photoURL,
            providerData: account.providerData ? account.providerData : null,
            isPremium: account.isPremium,
            timezone: account.timezone
        });
        
        await setAccount(updatedAccount.payload);
        localStorage.setItem('account', JSON.stringify(updatedAccount.payload));

        setNotification({
            display: true,
            header: 'Account Updated!',
            content: 'Your account changes have been saved.'
        });
    }

    const logout = () => {
        auth.signOut().then(function() {
            setAccount(null);
            setMyProfile(null);
            localStorage.removeItem('account');
        }, function(error) {
            console.error('Sign Out Error', error);
        });
    }

    const secondaryNavigation = [
        { name: 'Account', href: '#', current: true },
        { name: 'Billing', href: 'https://billing.stripe.com/p/login/dR67tq37h81vgoM3cc', target: "_blank", current: false }
        /*
        { name: 'Family', href: '#', current: false },
        { name: 'Notifications', href: '#', current: false },
        { name: 'Integrations', href: '#', current: false },
         */
    ]

    return(
        <main>
            <h1 className="sr-only">Account Settings</h1>

            <header className="bg-white dark:bg-slate-900 border-b border-gray-200 dark:border-slate-800">
                {/* Secondary navigation */}
                <nav className="flex overflow-x-auto py-4">
                <ul
                    role="list"
                    className="flex min-w-full flex-none gap-x-6 px-4 text-sm leading-6 text-gray-500 sm:px-6 lg:px-8"
                >
                    {secondaryNavigation.map((item) => (
                    <li key={item.name}>
                        <a href={item.href} target={item.target? item.target : '_self'} className={item.current ? 
                            'text-green-600 dark:text-green-500'
                            : 'text-gray-800 hover:text-gray-500 dark:text-slate-400 dark:hover:text-slate-300'}>
                        {item.name}
                        </a>
                    </li>
                    ))}
                </ul>
                </nav>
            </header>

            {/* Settings forms */}
            <div className="m-4">

                <Card 
                    header="Personal Information"
                    description="Use a permanent address where you can receive mail."
                >

                    <form className="md:col-span-2">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                            <div className="col-span-full flex items-center gap-x-8">
                                <img
                                src={account?.photoURL ? account.photoURL : accountImagePlaceholder}
                                alt=""
                                className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
                                />
                                <div>
                                    <Button disabled={true}>
                                        Change avatar
                                    </Button>
                                    <p className="mt-2 text-xs leading-5 text-gray-500 dark:text-slate-300">JPG, GIF or PNG. 1MB max.</p>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-800 dark:text-slate-400">
                                First name
                                </label>
                                <div className="mt-2">
                                <InputField
                                    name="firstName"
                                    id="firstName"
                                    control={control}
                                />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-800 dark:text-slate-400">
                                Last name
                                </label>
                                <div className="mt-2">
                                    <InputField
                                        name="lastName"
                                        id="lastName"
                                        control={control}
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-800 dark:text-slate-400">
                                Email address
                                </label>
                                <div className="mt-2">
                                    <InputField
                                        id="email"
                                        name="email"
                                        type="email"
                                        /* disabled={user?.providerData} */
                                        disabled={true}
                                        control={control}
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="timezone" className="block text-sm font-medium leading-6 text-gray-800 dark:text-slate-400">
                                Timezone
                                </label>
                                <div className="mt-2">
                                    <SelectMenu
                                        id="timezone"
                                        name="timezone"
                                        control={control}
                                        options={TIMEZONE_OPTIONS}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mt-8 flex">
                            <Button
                                type="submit"
                                onClick={onSave}
                                primary={true}>
                                Save
                            </Button>
                        </div>
                    </form>

                </Card>

            </div>

            <div className="col-span-full p-8">
                <Button
                    type="button"
                    onClick={logout}
                    warn={true}>
                    Log Out
                </Button>
            </div>

            <Notification data={ notification } />
        </main>
    )
}
 
export default Settings;