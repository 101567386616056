import account from './Account';
import profile from './Profile';
import wellness from './Wellness';
import calendar from './Calendar';
import post from './Post';
import friendRequests from './Notification/FriendRequests';

const stateModel = {
  account,
  profile,
  wellness,
  calendar,
  post,
  friendRequests
};

export default stateModel;
